<template>
  <div class="section-wrapper bfti-dashboard-wrapper">
    <breadcumb />
    <b-row>
        <b-col>
            <b-card-title class="text-center"><h5>{{ getTitle }}</h5></b-card-title>
            <b-card class="mt-1" style="min-height: 400px">
                <div v-if="$i18n.locale === 'en'" style="font-size: 25px" class="text-center mt-5">
                      <div>Welcome to <br><strong>Integrated Digital Service Delivery Platform</strong></div>
                </div>
                <div v-else style="font-size: 25px" class="text-center mt-5">
                      <div>বাণিজ্য মন্ত্রণালয় এর <b>সমন্বিত ডিজিটাল সেবা বাতায়ন</b> এ আপনাকে স্বাগতম</div>
                </div>
                <hr>
                <!-- <h4 class="text-center"> Your all services are here</h4> -->
                <div class="text-center">
                    <p class="h6">
                        <marquee width="70%" direction="left">
                            <h5><i class="ri-arrow-left-circle-fill"></i> {{ $i18n.locale === 'en' ? 'Your all services are here' : 'আপনার সকল সেবাসমূহ এখানে' }}</h5>
                        </marquee>
                    </p>
                </div>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  // import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
    data () {
      return {
        form: {
        employee_user_id: 0
        },
        loading: false
      }
    },
    mounted () {
      this.form.employee_user_id = this.$store.state.Auth.authUser.user_id
      this.loadData()
    },
    computed: {
      getTitle () {
        return this.$i18n.locale === 'en' ? 'Tea Garden External Panel' : 'চা বাগানের বাহ্যিক প্যানেল'
      },
      ...mapGetters({
          authUser: 'Auth/authUser'
      })
    },
    methods: {
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.form = []
      this.loadData()
    },
    mounted () {
    if (this.$store.state.Auth.activeRoleId !== 0) {
      this.form.employee_user_id = this.$store.state.Auth.authUser.user_id
    }
    this.loadData()
  },
    async loadData () {
      this.loading = true
    }
  }
  }
</script>
<style>
  .external-db-sec{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  .external-db-sec .info-card{
    padding: 12px;
    color: #fff;
    border-radius: 8px;
    background: #5725cf;
  }
  .external-db-sec .icd-1{
    background: #5725cf;
  }
  .external-db-sec .icd-2{
    background: #27c7a9;
  }
  .external-db-sec .icd-3{
    background: #d34a34;
  }
  .external-db-sec .info-card .info-value{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .external-db-sec .info-card .info-value .icon i{
    font-size: 30px;
  }
  .bfti-title{
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 1rem;
    background: #214162;
  }
  .bfti-title p{
    font-size: 18px;
  }
</style>
